import HttpRequest from "./http_request";
import { api_url } from "../config";

export default class PromoProvider extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async checkPromoCode(code, sp_id) {
    let user_token = localStorage.getItem("user_token");

    if (user_token === undefined || user_token === null) {
      return null;
    }

    let auth = {
      Authorization: "Bearer " + user_token
    };
    this.setHeader(auth);
    const { data } = await this.create(`promo/${sp_id}`, { name: code });

    return data;
  }
}
