import HttpRequest from "./http_request";
import { api_url } from "../config";
const prefix = "/store";
class StoreService extends HttpRequest {
  constructor() {
    super(api_url);
  }

  async index_bundles(page, limit) {
    const { data } = await this.get(
      `${prefix}/index-bundle${page ? `?page=${page}` : ""}${
        limit ? `&limit=${limit}` : ""
      }`
    );
    return data;
  }

  async get_bundle(bundle_id) {
    const { data } = await this.get(`${prefix}/get-bundle/${bundle_id}`);
    return data;
  }

  async get_bundle_refno() {
    const { data } = await this.get(`payment/refno/bundle`);
    return data;
  }

  async get_user(userObj) {
    const { data } = await this.create(`portal/getuser/`, userObj);
    return data;
  }
  
  async get_user_for_payment(userObj) {
    const { data } = await this.create(`portal/user-for-payment/`, userObj);
    return data;
  }

  async get_paid_bundle(refno) {
    const { data } = await this.get(`${prefix}/get-bundle-paid?refno=${refno}`);
    return data;
  }

  async check_promotion({ email, phone_number, code }) {
    const { data } = await this.create(`${prefix}/check-promotion`, {
      email,
      phone_number,
      code
    });

    return data;
  }
  
  async check_bundle_limit({ phone_number, email }, id, amount) {
    const { data } = await this.get(`${prefix}/check-bundle-limit/${id}?amount=${amount}&email=${email}`);
    console.log(data);
    
    return data.allow;
  }
}

export default StoreService;
