<template>
  <div class="CreateMatch-container">
    <div class="CreateMatch-body">
      <div class="body-content">
        <div class="content-container">
          <div>
            <h1>Booking Details</h1>
            <span class="title-description">กรุณาตรวจสอบรายละเอียดการจองให้ครบถ้วน เมื่อชำระเงินแล้ว จะไม่สามารถคืนเงินได้ </span>

            <div class="court-selected">
              <div class="court-card">
                <img :src="provider.logo" />
                <div>
                  <h2>{{provider.fullname}}</h2>
                  <span>{{provider.location}}</span>
                </div>
              </div>
              <div class="court-card">
                <img :src="sport_icon" />
                <div class="court-info">
                  <h2>{{render_format(match.time_start,'DD/MM/YYYY')}} เวลา {{render_format(match.time_start, 'HH:mm')}} - {{render_format(match.time_end, 'HH:mm')}}</h2>
                  <span>{{court_type_name}}</span>
                </div>
              </div>
            </div>
          </div>

          <div class="court-selected">
            <span>บริการเสริม</span>

            <div v-if="services.length" class="services-container">
              <div v-for="{ name, price },i in services" :key="i.key">
                <div class="court-info">
                  <span>{{name}}</span>
                  <span>{{price}}</span>
                </div>
              </div>
            </div>
            <div v-else class="services-container">
              <div class="court-info">
                  <span style="text-align: center;color: #dddddd">- ไม่มี -</span>
              </div>
            </div>
            <div style="display: flex;align-items: center">
              <input type="checkbox" v-model="match.room_switch" disabled/>
              <div style="color: #dddddd">
                <h2>สร้างแมทช์นี้เป็นแมทช์สาธารณะ</h2>
                <span class="title-description">ผู้เล่นบนแพลตฟอร์มสามารถเข้าร่วมได้ผ่านแอป</span>
              </div>
            </div>
          </div>

        </div>

        <div class="content-container">
          <div>
            <h1>Payment Details</h1>
            <div class="court-selected">
              <div class="body-content" style="justify-content: space-between; margin: 0.25rem 0;">

                <div class="court-info" style="width:45%">
                  <label>ชื่อ (Name)*</label>
                  <input id="firstname" type="text" v-model="firstname" />
                </div>
                <div class="court-info" style="width:45% ;">
                  <label>นามสกุล (Surname)*</label>
                  <input id="lastname" type="text" v-model="lastname" />
                </div>
              </div>

              <div class="court-info" style="margin: 0.25rem 0;">
                <label>อีเมล (E-mail)* <span style="font-size: 12px;color: #e21e1e;" v-if="email_error">(รูปแบบอีเมลไม่ถูกต้อง!)</span></label>
                <input id="email" type="text" v-model="email" />
              </div>

              <div class="court-info" style="margin: 0.25rem 0;">
                <label>เบอร์โทรศัพท์มือถือ (Mobile phone no.)* <span style="font-size: 12px;color: #e21e1e;" v-if="phone_error">(รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง!)</span></label>
                <input id="phone_number" type="text" v-model="phone_number" />
              </div>

            </div>
          </div>

          <div class="court-selected">
            <div v-for="{ name, price },i in payment_list" :key="i.key">
              <div class="body-content">
                <span>{{name}}</span>
                <span>{{price}}</span>
              </div>
            </div>

            <div class="store-input-placeholder" style="align-items: center">
              <div style="position: relative">
                <input
                  class="store-input"
                  v-model="voucher_promo"
                  :placeholder="`${is_logged? 'กรอกโค้ด (ถ้ามี)':'เข้าสู่ระบบเพื่อใช้โปรโมชั่น'}`"
                  type="text"
                  style="font-size: 1rem;height:fit-content;margin-right: 1rem;padding: 0.375rem 0.75rem;border-radius: 5px"
                  :disabled="promo_stage == 3 || !is_logged"
                />

                <a
                  @click="reset_promo()"
                  :style="promo_stage == 3 ? '' : 'display: none'"
                  class="cancel-promo"
                >
                  <i class="link remove_promo fas fa-trash-alt"></i>
                </a>
              </div>

              <button
                v-if="promo_stage == 2"
                @click="redeem_promo()"
                type="button"
                style="width: 10rem;border-radius: 5px;background-color: #e21515;border-color: #e21515;box-shadow:0 0.5rem 1rem rgba(0,0,0,0.15)"
                class="btn btn-success"
                :disabled="promo_stage == 3"
              >
                คลิกเพื่อใช้งาน
              </button>

              <button
                v-else-if="promo_stage == 3"
                type="button"
                style="width: 10rem;border-radius: 5px;background-color: #e21515;border-color: #e21515;"
                class="btn btn-success"
                :disabled="promo_stage == 3"
              >
                กำลังใช้งาน
              </button>

              <button v-else-if="loading_promo" class="btn btn-success" style="width: 10rem;border-radius: 5px;border-color: white;background-color: white">
                <img style="height: 1rem;" :src="loading_img_url" />
              </button>

              <button
                v-else
                @click="check_promo(voucher_promo)"
                type="button"
                style="width: 10rem;border-radius: 5px;background-color: #e21515;border-color: #e21515;box-shadow:0 0.25rem 0.5rem rgba(0,0,0,0.15)"
                class="btn btn-success"
                :disabled="promo_stage == 3 || !is_logged"
              >
                ตรวจสอบ
              </button>
            </div>
            <div v-if="promo_stage > 0">
              <div v-if="promo_code && promo_stage == 3" class="upper-price">
                <div>
                  <img src="@/assets/discount_tag.png" />
                  <span style="padding-left: 0.25rem">ส่วนลดจาก {{ voucher_promo }}</span>
                </div>
                <span style="color: red">- {{ promotion_calculator() }} ฿</span>
              </div>
              <div v-else-if="promo_stage <= 2 && promo_code" class="upper-price">
                <span style="font-size: 1rem; color: #A7ABBE">ส่วนลด
                  <span style="color: rgb(0,152,129)">{{voucher_promo}}</span>
                  {{promo_code
                      ? `พร้อมใช้งาน! - มอบส่วนลด ${promo_code.var.value}${promo_code.var.type == 'percent' ? "%" : " ฿"}`
                      : "ไม่ถูกต้อง หรือ ไม่อยู่ในเงื่อนไขการใช้งาน"
                  }}
                </span>
              </div>
              <div v-else class="upper-price">
                <span style="font-size: 1rem; color: red">ไม่พบคูปอง !</span>
              </div>
            </div>

            <div>
              <div class="body-content" style="justify-content: space-between; margin:0.5rem 0">
                <span>ค่าเช่าสนาม</span>
                <span v-if="total_price">{{original_price}}</span>
                <span v-else class="loading" style="min-width: 60px"></span>
              </div>
            </div>

            <div class="body-content" style="justify-content: space-between; margin:0.5rem 0">
              <span>รวมราคาสุทธิ</span>
              <span v-if="total_price" style="color: #F50201">{{total_price}}</span>
              <span v-else class="loading" style="min-width: 60px"></span>
            </div>
          </div>

        </div>
      </div>

      <div class="body-footer">
        <h1>วิธีการชำระเงิน (Payment Method)</h1>
        <div class="footer-input">
          <input type="radio" v-model="pay_online" :value="true" />
          <div>
            <h2>ชำระทันที</h2>
            <span>ชำระเงินออนไลน์ปลอดภัย 100% ผ่าน Pay Solution</span>
          </div>
        </div>

        <div class="footer-input">
          <input type="radio" v-model="pay_online" :value="false" :disabled="!is_logged || promo_stage == 3 || true"/>
          <div>
            <h2>ชำระที่สนาม <label class="title-description" v-if="!is_logged">(เข้าสู่ระบบเพื่อใช้งาน)</label></h2>
          </div>
        </div>
      </div>

    </div>
    <div class="CreateMatch-footer">
      <span style="font-size: 12px">เมื่อคุณกดปุ่ม “จ่ายเงิน” คุณยอมรับ <a href="/terms" target="_blank">ข้อกำหนดการใช้งาน</a> และ <a href="/privacy-policy" target="_blank">นโยบายความเป็นส่วนตัว</a> ของแมทช์เดย์</span>
      <PillButton
        :disabled="total_price == null"
        bg="var(--primary)"
        theme="dark"
        @click="gotoPayment()">จองสนาม</PillButton>
    </div>

    <paysoRedirect
      :refno="refno"
      :email="email"
      :detail="detail"
      :price="total_price"
      :trigger="to_payment"
    />

  </div>
</template>

<script>
import { CommonElement } from "../components/styled";
import moment from "moment";
import SportProvider from "../resources/sportProvider_provider";
import paysoRedirect from './paysoRedirect.vue'
import PortalService from "../resources/portal_service";
import UserProvider from "../resources/user_provider";
import PromoProvider from "../resources/promoProvider_provider";
import StoreService from "../resources/store_service";


export default {
  components: {
    ...CommonElement,
    paysoRedirect
  },
  data () {
    return {
      provider: {
        id: 1,
        fullname: 'Matchday Arena Test',
        logo: '',
        location: ''
      },
      firstname: null,
      lastname: null,
      email: null,
      phone_number: null,
      sport_icon: '',
      court_type_name: '',
      promo: '',
      promo_stage: 0,
      voucher_promo: null,
      loading_promo: false,
      loading_img_url: 'https://cdn.discordapp.com/attachments/780994260471775272/983601360404615178/S__41820187.gif',
      promo_code: '',
      match: {
        time_start: '',
        time_end: '',
        courts: [],
        room_switch: 0,
        payment: 'online',
        payment_multi: false
      },
      // total_price: null,
      original_price: null,
      default_format: 'YYYY-MM-DD HH:mm:ss',
      services: [],
      payment_list: [],
      is_logged: localStorage.getItem('user_token')? true:false,
      pay_online: true,
      to_payment: false,
      refno: '',
      detail: '',
      email_error: false,
      phone_error: false,
      reg: {
        email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/g,
        phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g
      }
    }
  },
  computed: {
    total_price () {
      return Number((this.original_price - this.promotion_calculator()).toFixed(2))
    }
  },
  methods: {
    reset_promo() {
      this.promo_stage = 0;
      this.promo_code = null;
      this.voucher_promo = null;
    },
    async check_promo(input) {
      this.loading_promo = true
      if (input) {
        const service = new PromoProvider();
        const res = await service.checkPromoCode(input, this.provider.id)
        this.loading_promo = false

        if (res.status != 'Fail') {
          this.promo_code = res;
          this.promo_stage = 2;
        } else {
          this.promo_stage = 1;
        }
      }
    },
    redeem_promo() {
      this.pay_online = true
      this.match.settings = {
        promotion_id: this.promo_code.promotion_id
      }
      this.promo_stage = 3;
    },
    promotion_calculator() {
      if (this.promo_code && this.promo_stage == 3) {
        const { type, value } = this.promo_code.var;
        var total_value = this.original_price;
        var total_reduce = type == 'percent'
          ? (total_value * value) / 100
          : value;
        return total_reduce
      } else {
        return 0;
      }
    },
    render_format(time, format) {
      return moment(time, this.default_format).format(format)
    },
    async getRefNo() {
      const portal = new PortalService()
      const { refno } = await portal.get_refno('Match')
      return refno
    },
    get_product_detail(id, user_id) {
      return `Product-Match-${id}-Amount-1-User-${user_id}`
    },
    async CreateMatch() {
      this.match.payment = this.pay_online? 'online':'offline'
      this.match.payment_multi = false
      const store_service = new StoreService()
      const user_service = new SportProvider()
      const { token, status } = await store_service.get_user_for_payment({
        fullname: `${this.firstname} ${this.lastname}`,
        email: this.email,
        phone_number: this.phone_number
      })
      if (status == 'fail') {
        this.test_input()

      } else {
        const { matches } = await user_service.createMatch(this.match, token)
        return matches[0]
      }
    },
    async gotoPayment() {
      if (this.firstname && this.lastname && this.email && this.phone_number && this.test_input()) {
        if (this.pay_online) {
          const { id, user_id } = await this.CreateMatch()
          if (id && user_id) {
            this.refno = await this.getRefNo()
            this.detail = this.get_product_detail(id, user_id)
            this.to_payment = true
            
            localStorage.setItem(
            "latest_created_match",
              JSON.stringify({ 
                match_id: id,
                email: this.email,
                match_price: this.total_price,
                provider: this.provider, 
                court_type_detail: this.court_type_name,
                match: this.match
              })
            );
          }
        } else {
          const { id } = await this.CreateMatch()
          localStorage.setItem(
            "latest_created_match",
            JSON.stringify({ 
              match_id: id,
              email: this.email,
              match_price: this.total_price,
              provider: this.provider, 
              court_type_detail: this.court_type_name,
              match: this.match
            })
          );
          this.$router.push('/payment-status')
        }

      } else {
        const fields = {
          0: 'firstname',
          1: 'lastname',
          2: 'email',
          3: 'phone_number'
        }
        const inputs = [this.firstname, this.lastname, this.email, this.phone_number]
        for (var i = 0; i < inputs.length ; i++) {
          var field = inputs[i];
          if (!field) {
            this[`${field}_error`] = true
            document.getElementById(fields[i]).focus()
            break;
          }
        }
      }
    },
    async get_court_price({ provider: { id }, courts, time_start, time_end }) {
      try {
        const provider_service = new SportProvider()
        const { free } = await provider_service.getFreeCourt({ id, courts, time_start, time_end });
        const { price } = free.find(({ id }) => id == courts)

        this.original_price = price
      } catch (err) {
        this.$router.back()
      }
    },
    async init_book_data() {
      const { query: { provider, date, start, end, courtID, courts, type } } = this.$route
      const sportProvider = new SportProvider();
      
      this.court_type_name = courts
      this.match = {
        provider: { id: provider },
        courts: [courtID],
        time_start: `${date.replaceAll('/','-')} ${start}:00`,
        time_end: `${date.replaceAll('/','-')} ${end}:00`,
        method: 'Landing-Page'
      }

      this.get_court_price(this.match)
      const { id, fullname, logo, location, provider_sports } = await sportProvider.getProfile(provider);
      this.provider = { id, fullname, logo, location }

      const { sport: { icon } } = provider_sports.find( ({ court_types }) => court_types.map(({ id }) => id).includes(parseFloat(type)) )
      this.sport_icon = icon
    },
    async init_user_data() {
      if (localStorage.getItem("user_token")) {
        const user_service = new UserProvider()
        const { firstname, lastname, email, phone_number } = await user_service.getUser()
        this.firstname = firstname
        this.lastname = lastname
        this.email = email
        this.phone_number = phone_number
      }
    },
    check_device() {
      if (/android/i.test(navigator.userAgent)) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.matchdayhub.application'
      }
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        window.location.href = 'itms-appss://apps.apple.com/th/app/matchday/id1504500902'
      }
    },
    check_login() {
      setInterval(() => {
        this.is_logged = localStorage.getItem('user_token')? true:false
        const filled = (this.firstname || this.lastname || this.email || this.phone_number)
        if (this.is_logged && !filled) this.init_user_data()
      },500)
    },
    test_input() {
      if (this.reg['email'].test(this.email)) this.email_error = false
      else this.email_error = true
      if (this.reg['phone'].test(this.phone_number)) this.phone_error = false
      else this.phone_error = true
      if (!this.email_error && !this.phone_error) return true
    }
  },
  mounted() {
    this.check_device()
    this.init_book_data()
    this.init_user_data()
    this.check_login()
  }
};
</script>

<style lang="scss">
  .store-input::placeholder {
    color: #e21515
  }
  .cancel-promo {
    position: absolute;
    cursor: pointer;
    right: 24px;
    top: 6px;
  }
  .upper-price {
    display: flex;
    justify-content: space-between;
    margin: 0.6vw 0;
  }
  .store-input-placeholder {
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
  }
  .court-card {
    display: flex;
    align-items: center;
  }
  .court-card > img {
    height: 50px;
    margin: 0.5rem;
  }
  .services-container {
    margin: 1rem
  }
  .CreateMatch-footer {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .footer-input {
    display: flex;
    align-items: center;
    margin: 1rem;
  }
  .body-footer {
    padding: 0rem 5rem;
    margin: 1rem 5vw;
  }
  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .court-info {
    display: flex;
    flex-direction: column;
  }
  .court-selected {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0.5rem 0;

    border: 1px solid black;
    border-radius: 10px;
    padding: 1rem;
  }
  .body-content {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100%;
  }
  .CreateMatch-container {
    padding: 5vw;
  }
  .title-description {
    font-size: 12px;
    font-weight: 400;
  }
  h1 {
    margin: 0;
    font-size: 20px;
    font-weight: 500;
  }
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
  }
  span {
    font-size: 16px;
    font-weight: 400;
  }
  input[type=checkbox], input[type=radio] {
    transform: scale(2);
    margin: 1rem;
  }
  input {
    padding: 0.5rem;
    background-color: #F7F4F4;
    border: none;
    border-radius: 5px
  }

  .loading:empty {
    min-height: 24px;
    background: 
      linear-gradient(0.25turn, transparent, #fff, transparent),
      linear-gradient(#eee, #eee),
      radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
      linear-gradient(#eee, #eee);  
    background-repeat: no-repeat;
    background-size: 315px 250px, 315px 180px, 100px 100px, 225px 30px; 
    background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
    animation: loading 1.5s infinite;
  }
  @keyframes loading {  
    to {
      background-position: 315px 0, 0 0, 0 190px, 50px 195px;
    }
  }
</style>