<template>
  <div v-if="pay_method != 0" style="display:none">
    <form
      id="inputValue"
      method="post"
      action="https://www.thaiepay.com/epaylink/payment.aspx"
    >
      <input id="refno" type="hidden" name="refno" value=" " />
      <input type="hidden" name="merchantid" value="29221002" />
      <input id="cusMail" type="hidden" name="customeremail" value=" " />
      <input type="hidden" name="cc" value="00" />
      <input id="proDetail" type="hidden" name="productdetail" value=" " />
      <input id="total" type="hidden" name="total" value=" " />
      <input type="hidden" name="returnurl" :value="return_url" />
      <br />
      <input
        id="pay-trigger"
        type="submit"
        name="Submit"
        value="Comfirm Order"
      />
    </form>
  </div>
</template>

<script>
import UserProvider from "../resources/user_provider";
export default {
  props: {
    refno: String,
    email: String,
    detail: String,
    price: String,
    trigger: Boolean,
    pay_method: String,
    bookingDetail: Object
  },
  data() {
    return {
      return_url: "https://matchday.co.th/payment-status"
    };
  },
  watch: {
    trigger: function(newTrigger) {
      if (newTrigger && this.pay_method != "0" && this.price != "0") {
        if ((this.refno && this.email && this.detail && this.price) !== null) {
          document.getElementById("refno").value = this.refno;
          document.getElementById("cusMail").value = this.email;
          document.getElementById("proDetail").value = this.detail;
          document.getElementById("total").value = this.price;
          document.getElementById("pay-trigger").click();
        } else {
          console.log("There are something wrong. Please check input data.");
        }
      } else {
        this.$router.push("/payment-status");
        // save payment because price = 0
        if (this.price == "0") {
          let user_provider = new UserProvider();
          user_provider.payment({
            refno: "00000" + this.refno,
            cardtype: "",
            productdetail: this.detail,
            total: this.price
          });
        }
      }
    }
  }
};
</script>
